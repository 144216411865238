//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
	.content {
		padding: get($content-spacing, desktop) 0;

		// toolbar enabled & toolbar not fixed modes
		.toolbar-enabled:not(.toolbar-fixed) & {
			padding-top: get($content-spacing-toolbar, desktop);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.content {
		padding: get($content-spacing, tablet-and-mobile) 0;

		// toolbar enabled & toolbar not fixed modes
		.toolbar-enabled:not(.toolbar-tablet-and-mobile-fixed) & {
			padding-bottom: get($content-spacing-toolbar, tablet-end-mobile);
		}
	}
}
